import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import Slide from '../Slide/Slide';
import './LeftSection.css';

const LeftSection = props =>{
    const server = "https://jlmyall.lat/joey";
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([{name:"", alt:""}])

    useEffect(() => {
        async function fetchData(){
            const images_res = await axios.get(server+"/video/ord-cat/"+props.category);
            setImages(images_res.data);
        }
        fetchData()
    },[props.category])

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleClick = () => {
        props.play([true, images[currentIndex]]);
    };

    return(
        <div className='body-box'>
            <div className='info-row'> 
                <div className='info-column'>
                    <div className="button-slider-container" style={{ width: "50vw", height: "40vw" }}>
                        <img
                            src={images[currentIndex].alt === 'none' ? "https://img.youtube.com/vi/"+images[currentIndex].tag+"/maxresdefault.jpg" : "https://f005.backblazeb2.com/file/PhotosSite/Thumbnails/"+images[currentIndex].alt}
                            alt={`slide-${currentIndex}`}
                            className="button-slider-image"
                            onClick={handleClick}
                        />
                        <button className="button-slider-button button-slider-button-left" onClick={goToPrev}>
                            {'<'}
                        </button>
                        <button className="button-slider-button button-slider-button-right" onClick={goToNext}>
                            {'>'}
                        </button>
                        <div className="button-slider-item-name">{images[currentIndex].name}</div>
                    </div>
                </div>
                <div className='info-column'>
                    <div className='left-title'>{props.category}</div>
                    <div className='left-info'>{""}</div>
                </div>
            </div>
        </div>
    );
}

export default LeftSection
